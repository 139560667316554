<template>
    <div class="page-content w-100">
      <div class="row g-0">
        <div class="col-sm-12">
          <p class="text-center page-heading">Home</p>
        </div>
      </div>
      <div class="messageBox">
        <div class="row">
        
          <!-- admin page practice area add -->
          <div>
            <div class="box-shadow2 cardBorder"  v-for="index in filtercheckValue" :key="index">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">
                    New community for doctors added to RxIx
                  </h4>
                </div>
              </div>
              <div class="row box-items">
                <div class="col-opt">
                  <div class="col-fix mt-3">
                    <div class="btn-text">
                      {{ index.practiceArea }}
                      <div>Doctors</div>
                    </div>
                  </div>
                  <div>
                    <button class="btn btn-action">Join community</button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-12 mt-5">
                  Once you join the community,will also be added to your list of
                  practice specialties.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6 col-6">
                  <p class="text-start font-weight-bold">Best Wishes!</p>
                </div>
  
                <div class="col-md-6 col-6">
                  <p class="text-end">{{ index.updateAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
  
          <!-- admin approved message -->
          <!-- <div v-if="clinicianOthersData.updated_by == 'admin-customer' ">
            <div class="box-shadow2 cardBorder" >
                <div class="row mt-3">
                  <div class="col-md-12 col-12">
                    <h4 class="text-20 font-weight-bold">Invitation to manage {{clinicianOthersData?.customer?.companyName }}  on RxIx</h4>
                  </div>
                  </div>
                  <div class="row mt-3">
                  <div class="col-12 mt-3">
                    Greetings from RxIx!
                  </div>
                <div class="col-12 mt-3">
                  You have been added to {{clinicianOthersData?.customer?.companyName }} on RxIx.
                   You have also been provided with the privilege to administrate the account on the app. 
                   Please sign in to the app using <a href="https://rxix.in/signup">https://rxix.in/signup</a> .
                </div>
                  <div class="col-12 mt-3">
                    For any queries and support, feel free to connect with the RxIx WhatsApp Help Desk.
                  </div>
                        <div class="col-md-12 col-6">
                      <p class="text-end">{{ clinicianOthersData.createdAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
              </div>
          </div> -->
          <div v-if="clinicianOthersData.updated_by == 'admin-customer'  && clinicianOthersData.isActive == false" >
            <div class="box-shadow2 cardBorder" >
                <div class="row mt-3">
                  <div class="col-md-12 col-12">
                    <h4 class="text-20 font-weight-bold">Access to manage {{clinicianOthersData?.customer?.companyName }} on RxIx is removed</h4>
                  </div>
                  <div class="col-12 mt-3">
                    On the directions of {{clinicianOthersData?.customer?.companyName }},your privilege to administrate the account on RxIx is removed.
                  </div>
                </div>
                <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">You now have access to regular features applicable to your user type.</p>
                </div>
                <div class="col-12 mt-3">
                  For any queries, feel free to connect with the RxIx WhatsApp Help Desk.</div>
                <div class="col-md-12 col-6">
                  <p class="text-end">{{ clinicianOthersData.createdAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
              </div>
          </div>
          <div   v-for="doctorGroup in allUniqueClinicanOthersHistoryData" :key="doctorGroup">
            <div v-if="doctorGroup.patientGroupStatus === 'patient_group_created'">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Membership - Patients group</h4>
                </div>
                <div class="col-12 mt-3">
                  You are now part of the
                  {{doctorGroup.clinicianGroupName.group_name }} patients group. To interact and engage with the community, please ensure that your profile is complete.
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <p class="text-start font-weight-bold">RxIx Admin</p>
                </div>
                <div class="col-md-12 col-6">
                  <p class="text-end">{{ doctorGroup.createdAt }}</p>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
            <div v-if="doctorGroup.patientGroupStatus === 'patient_group_deleted'">
            <div class="box-shadow1 cardBorder"  >
                <div class="row mt-3">
                  <div class="col-md-12 col-12">
                    <h4 class="text-20 font-weight-bold">Membership - Patients group</h4>
                  </div>
                  <div class="col-12 mt-3">
                    Since you have removed {{ doctorGroup.clinicianGroupName.groupname }} from your
                    patient groups, you are no more a member of the group.
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-12 col-6">
                    <p class="text-start font-weight-bold">RxIx Admin</p>
                  </div>
                  <div class="col-md-12 col-6">
                    <p class="text-end" style="float: right">
                      {{ doctorGroup.createdAt }}
                    </p>
                  </div>
                </div>
                <nav class="nav nav-fill feed-icon">
                  <router-link to="" title="Send private" class="nav-link feed-icon-link">
                    <font-awesome-icon :icon="['fas', 'paper-plane']" />
                    <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                  </router-link>
                </nav>
            </div>
            </div>
           
        </div>
        <div v-if="clinicianOthersData?.clinic_admin_id">
      <div class="box-shadow2 cardBorder" >
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">You are now part of the team at {{ clinicTeamAdmin[0].clinic_name }}</h4>
              </div>
              <div class="col-12 mt-3">
                Welcome to the clinic’s team. Please complete your settings page. 
                If you have any queries about the app, 
                reach out to the RxIx Help Desk over WhatsApp on 82960 13460.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">{{ clinicianOthersData?.clinic_admin_name }}</p>
              </div>
              <div class="col-md-12 col-6">
                <p class="text-end" style="float: right">
                  {{clinicianOthersData.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
          </div>

                <div v-if="clinicianOthersData?.clinic_hcp_id && clinicianOthersData?.clinic_admin_id">
      <div class="box-shadow2 cardBorder" >
            <div class="row mt-3">
              <div class="col-md-12 col-12">
                <h4 class="text-20 font-weight-bold">You are now an administrator of {{ clinicAdmin[0].clinic_name }} on RxIx</h4>
              </div>
              <div class="col-12 mt-3">
                As a clinic administrator on RxIx, you have the privilege to add team members. 
                You may also connect and work with healthcare vendors registered on the app for 
                the benefit of your clinic’s operations as also to enhance what you offer to your patients.
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 col-6">
                <p class="text-start font-weight-bold">{{ clinicianOthersData?.clinic_admin_name }}</p>
              </div>
              <div class="col-md-12 col-6">
                <p class="text-end" style="float: right">
                  {{clinicianOthersData.createdAt }}
                </p>
              </div>
            </div>
            <nav class="nav nav-fill feed-icon">
              <router-link to="" title="Send private" class="nav-link feed-icon-link">
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
                <p class="feed-title d-none d-lg-block">Send message to Admin</p>
              </router-link>
            </nav>
          </div>
          </div>

          <!-- welcome message for all the cards-->
          <div v-if="clinicianOthersData">
            <div class="box-shadow2 cardBorder">
              <div class="row mt-3">
                <div class="col-md-12 col-12">
                  <h4 class="text-20 font-weight-bold">Welcome to RxIx</h4>
                </div>
                <div class="col-12 mt-3">
                  It is our pleasure to welcome you to RxIx. 
            Our initiative is to support doctors in their patient care efforts. 
                </div>
                <div class="col-12 mt-3">
                  Please take a quick tour of the app to learn about the features we currently offer.</div>
               
                <div class="d-none d-lg-block">
                <div class="col-12 mt-3">
                  Complete the<a class="anchorTag" @click="practiceDesktop()"> <u> About me </u></a> section to join groups. 
                  You also get to view a patient’s past consultation records across RxIx doctors and record your patient consultations.
                </div>
              </div>
              <div class="d-block d-lg-none">
                <div class="col-12 mt-3">
                  Complete the <a class="anchorTag" @click="practiceMobile()"><u> About me </u></a>section to join groups. 
                  You also get to view a patient’s past consultation records across RxIx doctors and record your patient consultations.
                </div>
              </div>
  
                <div class="row mt-3">
                  <p class="text-start font-weight-bold">Best Wishes!</p>
                  <div class="col-md-6 col-6">
                    <p class="text-start font-weight-bold">RxIx Admin</p>
                  </div>
  
                  <div class="col-md-6 col-6">
                    <p class="text-end">{{ clinicianOthersData.createdAt }}</p>
                  </div>
                </div>
              </div>
              <nav class="nav nav-fill feed-icon">
                <router-link to="" title="Send private" class="nav-link feed-icon-link">
                  <font-awesome-icon :icon="['fas', 'paper-plane']" />
                  <p class="feed-title d-none d-lg-block">Send message to Admin</p>
                </router-link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <clinicianotherfooter></clinicianotherfooter>
  </template>
  <script>
  import clinicianotherfooter from "./clinician-others-footer.vue"
  import { defineComponent } from "vue";
  import axios from "axios";
  import moment from "moment";
  export default defineComponent({
    components: {
        clinicianotherfooter
    },
    props: ["role", "approve"],
    data() {
      return {
        clinicianOthersData: {},
        button: [],
        doctorPatientGroup: [],
        allUniqueClinicanOthersHistoryData:[],
        allClinicData:[],
        clinicAdmin:{},
        clinicTeamAdmin:{}
      };
    },
    methods: {
      consultationPlace() {
        window.scrollTo(0, 0);
        this.$router.push({ name: 'Consultation-Place' });
      },
   
      async practiceDesktop() {
        const query = this.$route.query;
        window.scrollTo(0, 0);
        this.$router.push({ path: `/clinicianothers/settings`, query });
      },
      async practiceMobile() {
        const query = this.$route.query;
        window.scrollTo(0, 0);
        this.$router.push({ path: `/clinicianothers/mobile-settings`, query });
      },

      async getClinicianOthers() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            await axios
                .get(`${BASE_API_URL}/clinician-others/${this.clinicianOthersID}/one`)
                .then((response) => {
                    if (response?.data) {
                      response.data.createdAt = moment(response.data.createdAt).format("DD/MM/YYYY, h:mm a")
                      this.clinicianOthersData =response.data
                      return response.data
                        }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
            
        },
        getClinicConsultation(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
         axios
    .get(`${BASE_API_URL}/clinic-consultation`)
    .then(response => {
        this.allClinicData = response.data
        this.clinicAdmin=this.allClinicData.filter((data)=>{return data.id == this.clinicianOthersData.clinic_hcp_id})
        this.clinicTeamAdmin = this.allClinicData.filter((data)=>{return data.id == this.clinicianOthersData.clinic_admin_id})
    })
    },
  
      async getClinicianOthersHistory() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

  this.allUniqueClinicanOthersHistoryData = [];
  let createdData =[];
    await axios.get(`${BASE_API_URL}/clinician-others-history`)
    .then(async (response) => {
      response.data.map((date)=>{date.createdAt = moment(date.createdAt).format("DD/MM/YYYY, h:mm a")})
         createdData = response.data.filter(item => {
          return item.clinicianOthersPatientGroupId?.id === this.clinicianOthersID
        });
        createdData.map((item)=>{
          let createdDataDetials;
          createdDataDetials = {
            createdAt:item.createdAt,
            patientGroupStatus:item.clinician_others_status,
            clinicianGroupName:item.clinicianOthersPatientGroup,
          }
        this.allUniqueClinicanOthersHistoryData.push(createdDataDetials)
        })
  
  
      })
   .catch (function (error) {
        console.error("Error fetching data:", error);
      })
    },
    },
    created: async function () {
      let clinicianothers = localStorage.getItem('clinician-others-id')
      this.clinicianOthersID = JSON.parse(clinicianothers);
      if(this.clinicianOthersID){
      await this.getClinicianOthers();
      await this.getClinicianOthersHistory();
      await this.getClinicConsultation();
      }
    },
  });
  </script>
  <style>
  .feed-icon-link {
    color: #cccccc !important;
    pointer-events: none;
  }
  .anchorTag{
    font-weight: bold;
    color: #000;
    cursor: pointer;
    background-color: #b7b7b7;
  }
  .messageBox {
    padding: 1rem;
    margin: 1rem;
  }
  
  /* .wistomBorder {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
    margin-right: 15rem;
  } */
  .cardBorder {
    padding: 15px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
    border-radius: 20px;
  }
  
  span.textstyle {
    font-size: 20px;
    font-weight: 700px;
  }
  
  p.timestyle {
    font-size: 12px;
    color: #545759;
  }
  
  button.btn.btn-action {
    background: #00979e;
    color: #fff;
    width: 100%;
    border-radius: inherit;
  }
  
  .btn-text {
    text-align: center;
    margin: 15px;
    padding: 20px;
    color: #00979e;
  }
  
  .col-opt {
    width: 100%;
    display: grid;
    justify-content: center;
  }
  
  .col-fix.mt-3 {
    border: 2px solid #00979e;
    width: 206px;
    /* margin-bottom: 30px; */
  }
  
  button.btn.btn-blue-color.text-white {
    width: fit-content;
  }
  
  div.btn.btn-action-background1 {
    background: #fff;
    color: #00979e;
    width: 100%;
    border-radius: inherit;
    border:2px solid #00979e
  }
  
  .eyes-text1 p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center
  }
  
  .box-text-bg1 {
    font-size: 45px;
    color: #fff;
  }
  .col-opt-box1 {
    width: 100%;
    display: grid;
    justify-content: center;
  }
  
  .col-fix.mt-3.color-bg1 {
    border: 2px solid #00979e;
    width: 235px;
    /* margin-bottom: 30px; */
    background-color: #00979e;
  }
  
  .col-box {
    display: flex;
    justify-content: center;
    margin: 40px 40px 0px 40px;
    position: relative;
    bottom: 20px;
  }
  
  .btn-text-bg1 {
    text-align: center;
    padding: 15px;
    color: #fff;
    background-color: #00979e;
  }
  
  .btn.btn-action-background1 {
    color: #00979e;
  }
  
  .box-shadow1 {
    box-shadow: 0px 0px 0px 1px red, 0px 0px 0px 0px red !important;
  }
  
  .box-shadow2 {
    box-shadow: 0px 0px 0px 1px #00979e, 0px 0px 0px 0px #00979e !important;
  }
  
  @media screen and (max-width: 991px) {
    .col-box {
      display: flex;
      margin: auto;
    }
  
    .messageBox {
      padding: 0rem 0.5rem 0.5rem 0.5rem;
      margin: 0rem 0.5rem 0.5rem 0.5rem;
    }
  }
  
  @media screen and (max-width: 769px) {
    .div-text {
      margin-top: 1rem;
    }
  }
  </style>
  